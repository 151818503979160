'use client';
import './landing.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import Link from 'next/link';
import Script from 'next/script';

import { Toaster } from '@/components/ui/sonner';

import KoalaScript from './components/KoalaScript';
import Reb2bScript from './components/r2b';

export default function Layout({ children }: { params: { client: string }; children: React.ReactNode }) {
  return (
    <div className="bg-white absolute inset-0 white overflow-scroll text-black">
      <div className="w-full max-w-[1300px] mx-auto pt-[64px] px-5 h-full mb-20">
        <MenuBar />
        <div>
          <h1 className="text-[44px] font-bold max-w-[680px] pt-[70px] leading-[50px]">Fix your industry field in Salesforce</h1>
          <p className="max-w-[686px] text-base pt-4 leading-6">
            Grab all accounts with a missing industry and export as CSV. Upload your company-specific industry schema. Get an easy-to-upload CSV with all
            industries mapped.
          </p>
        </div>
        <div className="pt-[70px]">
          <span className="text-sm opacity-40">Agent</span>
          <h2 className="text-[28px] font-bold">Industry classification</h2>
        </div>
        {children}
      </div>
      <Toaster />
      <Script
        src="https://tag.clearbitscripts.com/v1/pk_ebc084ef3f01fb3537eedcc90897db99/tags.js"
        referrerPolicy="strict-origin-when-cross-origin"
        strategy="lazyOnload"
      />
      <KoalaScript />
      <Reb2bScript />
      <GoogleTagManager gtmId="G-E8X6T6E7XZ" />
      <Script id="google-ads" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-E8X6T6E7XZ');
          gtag('config', 'AW-11155055905');
        `}
      </Script>
    </div>
  );
}

function MenuBar() {
  return (
    <div className="w-full mx-auto flex justify-between items-center">
      <div className="flex items-center space-x-2">
        {/* <div className="h-7 w-7 bg-black rounded-md"></div> */}
        <Link href={'https://generatemomentum.ai'} target="_blank" className="text-2xl font-semibold">
          Momentum
        </Link>
      </div>
      <div className="space-x-11">
        <Link href={'https://generatemomentum.ai'} target="_blank" className="font-semibold text-sm">
          Visit homepage
        </Link>
        <Link
          href={'https://cal.com/team/momentum/momentum-founder'}
          target="_blank"
          className="bg-purpleLight text-white hover:bg-purple/90 font-semibold py-3 px-4 rounded-md text-sm"
        >
          {'Get a demo ->'}
        </Link>
      </div>
    </div>
  );
}
