import Script from 'next/script';
import React from 'react';

interface Reb2bMethods {
  identify: (...args: any[]) => any;
  collect: (...args: any[]) => any;
  [key: string]: (...args: any[]) => any;
}

interface Reb2bProperties {
  invoked?: boolean;
  methods?: string[];
  factory?: (method: string) => (...args: any[]) => any;
  push?: (...args: any[]) => void;
  SNIPPET_VERSION?: string;
  load?: (key: string) => void;
}

type Reb2b = Reb2bMethods & Reb2bProperties;

declare global {
  interface Window {
    reb2b: Reb2b;
  }
}

const Reb2bScript: React.FC = () => {
  return (
    <Script
      src="https://s3-us-west-2.amazonaws.com/b2bjsstore/b/LNKLDHM8P4OJ/reb2b.js.gz"
      strategy="lazyOnload"
      onLoad={() => {
        setTimeout(() => {
          window.reb2b = window.reb2b || ({} as Reb2b);
          if (window.reb2b.invoked) return;
          window.reb2b.invoked = true;
          window.reb2b.methods = ['identify', 'collect'];
          window.reb2b.factory = function (method: string) {
            return function (...args: any[]) {
              const allArgs = [method, ...args];
              window.reb2b.push?.(allArgs);
              return window.reb2b;
            };
          };
          for (const method of window.reb2b.methods) {
            window.reb2b[method] = window.reb2b.factory?.(method);
          }
          window.reb2b.SNIPPET_VERSION = '1.0.1';
        }, 2000);
      }}
    />
  );
};

export default Reb2bScript;
