import Script from 'next/script';
import { useCallback } from 'react';

export default function KoalaScript() {
  const initializeKoala = useCallback(() => {
    if (window.ko) return;

    window.ko = [] as any;
    ['identify', 'track', 'removeListeners', 'open', 'on', 'off', 'qualify', 'ready'].forEach((method) => {
      window.ko[method] = function (...args: any[]) {
        args.unshift(method);
        window.ko.push(args);
        return window.ko;
      };
    });
  }, []);

  return <Script src={'https://cdn.getkoala.com/v1/pk_af8608c9c062848d941edc74fe13243c2e0d/sdk.js'} strategy="lazyOnload" onLoad={initializeKoala} />;
}
